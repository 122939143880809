import { Box } from "@chakra-ui/react";
import { getCookie, LargeButton, LARGEL_BUTTON_TYPE } from "goi_common";
import { useRouter } from "next/router";
import { useMemo } from "react";

export async function getStaticProps() {
  return {
    props: {
      pageOgTitle: "장례의 모든 것, 고이",
    },
  };
}

function HomePage() {
  const router = useRouter();

  const account = useMemo(() => {
    const user = getCookie("teamjang_user");
    if (user && user !== "undefined") {
      return user;
    } else return null;
  }, []);

  return (
    <Box paddingTop="80px">
      <Box maxWidth="480px" margin="auto">
        <Box padding="24px" className="head_24_b gray900">
          고이 고객/정산관리
        </Box>
        <Box padding="12px" width="100%">
          <LargeButton
            className="button_14_sb"
            buttonType={LARGEL_BUTTON_TYPE.GRAY900}
            onClick={() => {
              router.push("/funeral/list/");
            }}
          >
            장례 건별 매출/비용 입력
          </LargeButton>
        </Box>
        {account && account.role === "SUPERUSER" && (
          <>
            <Box padding="24px" className="head_24_b gray900">
              본사-정산
            </Box>
            <Box padding="12px" width="100%">
              <LargeButton
                className="button_14_sb"
                buttonType={LARGEL_BUTTON_TYPE.GRAY900}
                onClick={() => {
                  router.push("/headquarter/account-transaction/");
                }}
              >
                입금 내역 확인
              </LargeButton>
            </Box>
            <Box padding="12px" width="100%">
              <LargeButton
                className="button_14_sb"
                buttonType={LARGEL_BUTTON_TYPE.GRAY900}
                onClick={() => {
                  router.push("/headquarter/funeral-list/");
                }}
              >
                출동 내역 전체 세부 관리
              </LargeButton>
            </Box>

            <Box padding="24px" className="head_24_b gray900">
              본사-고객
            </Box>
            <Box padding="12px" width="100%">
              <LargeButton
                className="button_14_sb"
                buttonType={LARGEL_BUTTON_TYPE.GRAY900}
                onClick={() => {
                  router.push("/funueral/list/");
                }}
              >
                고객 리드 관리
              </LargeButton>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default HomePage;
